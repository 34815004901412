import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58')
];

export const server_loads = [2,11,4,7,8,9,10];

export const dictionary = {
		"/(web)": [~12,[2],[3]],
		"/app/driver/[token]": [~58,[11]],
		"/(web)/blog": [~19,[2],[3]],
		"/(web)/blog/[category]": [~20,[2],[3]],
		"/(web)/blog/[category]/[slug]": [~21,[2],[3]],
		"/(web)/booking/[id]": [~22,[2],[3,5]],
		"/(web)/booking/[id]/checkout": [~23,[2],[3,5]],
		"/(web)/booking/[id]/confirm": [~24,[2],[3,5]],
		"/(web)/booking/[id]/details": [~25,[2],[3,5]],
		"/(web)/booking/[id]/passenger": [26,[2],[3,5]],
		"/(web)/booking/[id]/payment/[paymentId]": [~27,[2],[3,5]],
		"/(web)/booking/[id]/status": [~28,[2],[3,5]],
		"/(web)/(guest)/create-account": [~13,[2,4],[3]],
		"/(web)/how-it-works": [~29,[2],[3]],
		"/(web)/(guest)/login": [~14,[2,4],[3]],
		"/(web)/(guest)/login/google": [~15,[2,4],[3]],
		"/(web)/(guest)/login/google/callback": [~16,[2,4],[3]],
		"/(web)/(guest)/login/pin": [17,[2,4],[3]],
		"/(web)/myride/[id]": [30,[2,,7],[3,6]],
		"/(web)/myride/[id]/cancel": [31,[2,,7],[3,6]],
		"/(web)/myride/[id]/edit": [~32,[2,,7],[3,6]],
		"/(web)/myride/[id]/payment": [33,[2,,7],[3,6]],
		"/(web)/myride/[id]/payment/[paymentId]": [~34,[2,,7],[3,6]],
		"/(web)/myride/[id]/payment/[paymentId]/ok": [~35,[2,,7],[3,6]],
		"/(web)/myride/[id]/payment/[paymentId]/online": [~36,[2,,7],[3,6]],
		"/(web)/payment/[id]": [37,[2,8],[3]],
		"/(web)/payment/[id]/ok-check": [39,[2,8],[3]],
		"/(web)/payment/[id]/ok": [38,[2,8],[3]],
		"/(web)/payment/[id]/online": [~40,[2,8],[3]],
		"/(web)/privacy-policy": [~41,[2],[3]],
		"/(web)/questions": [~42,[2],[3]],
		"/(web)/search": [~43,[2],[3]],
		"/(web)/search/[id]": [~44,[2],[3]],
		"/(web)/taxi/[from]": [~45,[2],[3]],
		"/(web)/taxi/[from]/[to]": [~46,[2],[3]],
		"/(web)/terms-and-conditions": [~47,[2],[3]],
		"/(web)/travel-from-[from]-to-[to]": [~48,[2],[3]],
		"/(web)/user/profile": [~49,[2,9],[3]],
		"/(web)/user/profile/edit": [50,[2,9],[3]],
		"/(web)/user/profile/password": [51,[2,9],[3]],
		"/(web)/user/ride/[id]": [52,[2,9,10],[3]],
		"/(web)/user/ride/[id]/cancel": [~53,[2,9,10],[3]],
		"/(web)/user/ride/[id]/edit": [~54,[2,9,10],[3]],
		"/(web)/user/ride/[id]/payment": [55,[2,9,10],[3]],
		"/(web)/user/ride/[id]/payment/online": [~57,[2,9,10],[3]],
		"/(web)/user/ride/[id]/payment/[paymentId]/ok": [~56,[2,9,10],[3]],
		"/(web)/[...notfound]": [~18,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';